.breakingNewsContainer {
  height: 0;
  padding: 0;
  overflow: hidden;
  transition-duration: 400ms;
  background-color: #141414;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  box-sizing: content-box;
  max-width: 1400px;
  background-color: transparent;
}
.breakingNewsContainer > div {
  background-color: #eb0000;
}

.breakingNewsContainer.breakingNewsOpen {
  height: 72px;
}

.breakingNews {
  display: flex;
  justify-content: space-between;
  padding-right: 21px;
  height: 72px;
}

.breakingNewsContent {
  display: flex;
  max-width: 90%;
}

.breakingNewsContent .newsText {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
}

.breakingNewsContent .newsImage {
  flex-shrink: 0;
  width: 94px;
  height: 72px;
  border-radius: 4px;
}

.breakingNewsContent .newsImage img {
  width: 94px;
  height: 72px;
  object-fit: cover;
  object-position: center;
}

.breakingNewsContent .newsTitle,
.breakingNewsContent .newsHeadline {
  font-size: 12px;
  line-height: normal;
}

.breakingNewsContent .newsTitle {
  font-weight: 600;
  color: #fff;
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.38px;
}

.breakingNewsContent .newsHeadline {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #fff;
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
}

.breakingNewsCTA {
  display: flex;
  align-items: center;
}

.breakingNewsCTA span {
  display: none;
  margin-right: 24px;
  font-size: 19px;
}

.carouselContainer {
  display: flex;
  position: relative;
  grid-column: 1 / 3;
  /* height: 420px; */
  padding-bottom: 16px;
  overflow: hidden;
  border-radius: 8px;
  /* background-color: #000; */
}

.carouselContainer::before {
  content: '';
  position: absolute;
  z-index: 0;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  background-image: var(--background-image);
  background-position: center;
  background-size: auto 100%;
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px) brightness(0.4);
}

.slideTrack {
  display: flex;
  position: relative;
  left: 0;
}

.slideItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  margin: 16px 0 0 0;
  background-color: unset;
  transition-duration: all 0ms !important;
}

.isLive .slideItem {
  align-items: center;
}

.slideItem .tag {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #eb0000;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 125%;
  z-index: 2;
}

.slideItem .imgContainer {
  display: grid;
  position: relative;
  width: calc(100% - 8px);
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;
}
@media screen and (orientation: portrait) {
  .slideItem .imgContainer {
    touch-action: none;
  }
}
.slideItem img {
  width: 100%;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
  object-position: top;
  display: none;
}
.slideItem img.imgDesktop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.slideItem .imgContainer::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slideItem .slideLogo {
  position: absolute;
  bottom: 31%;
  width: 100%;
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.slideItem .slideLogo img {
  width: 50%;
}

.containerThing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
}
.slideItem .slideText {
  z-index: 1;
  bottom: 23%;
  width: 100%;
  color: black;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  padding: 0 12px;
  max-width: 92%;
  margin-bottom: 8px;
}
.slideText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.slideItem .slideCTA {
  display: flex;
  z-index: 1;
  bottom: 32px;
  justify-content: center;
  width: 100%;
}

.slideItem a {
  margin: 0 12px;
  font-size: 12px;
  font-weight: bold;
  line-height: 120%;
}

.slideItem .watchNow {
  display: flex;
  align-items: center;
  color: var(--Text-Default-Subtle, #525252);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.slideItem .watchNow > svg {
  display: none;
}

.slideItem .episodes {
  border-radius: 2px;
  color: var(--Text-Default-Subtle, #525252);
  position: relative;
  z-index: 10;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  display: none;
}

.playIcon {
  margin-right: 6px;
}

.indicatorsContainer {
  position: absolute;
  z-index: 2;
  bottom: 4px;
  left: 0;
  width: 100%;
  display: none;
}

.indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.indicatorOuter {
  /* width: 100%; */
  margin: 0 1.5px;
  padding: 8px 0;
  cursor: pointer;
}

.indicator {
  width: 8px;
  height: 8px;
  overflow: hidden;
  border-radius: 4px;
  background-color: #8f8f8f;
}
.indicator.isActive {
  animation: expand 0.5s ease forwards;
}

.indicatorInner {
  width: 0;
  height: 100%;
  border-radius: 3px;
  background-color: #eb0000;
}

.indicatorCurrent {
  animation: indicatorFill var(--slide-duration) linear forwards;
}

.indicatorEmpty {
  width: 0;
}

.indicatorFull {
  width: 100%;
  animation: indicatorFull calc(var(--transition-duration) * 0.5) ease-in-out forwards;
}
.chevronContainer {
  opacity: 0;
  display: none;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 25%;
  height: 100%;
  transition: opacity 200ms ease-in !important;
  cursor: pointer;
}
.chevronContainer:hover {
  opacity: 1;
}
.chevronContainer.right {
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}
.chevronContainer.left {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  left: 0;
}
.chevron {
  position: absolute;
  cursor: pointer;
}

.chevronLeft {
  left: 24px;
}

.chevronRight {
  right: 24px;
}

@media (min-width: 400px) {
  .slideItem a {
    font-size: 15px;
  }
}
@media (min-width: 500px) {
  .slideItem img {
    display: flex;
  }
}
@media (min-width: 750px) {
  .breakingNews {
    padding-right: 56px;
  }
  .breakingNewsContent {
    max-width: 80%;
  }
  .breakingNewsContainer {
    margin-top: 12px;
    padding: 0 5%;
  }
  .breakingNewsContent .newsImage,
  .breakingNewsContent .newsImage img {
    width: 168px;
    height: 72px;
  }

  .breakingNewsContent .newsText {
    padding-left: 24px;
    display: flex;
    gap: 0;
    flex-direction: column;
    justify-content: center;
  }

  .breakingNewsContent .newsTitle,
  .breakingNewsContent .newsHeadline {
    font-size: 19px;
  }

  .breakingNewsContent .newsTitle {
    margin-bottom: 0;
    flex-shrink: 0;
  }

  .breakingNewsCTA span {
    display: inline;
    flex-shrink: 0;
    background-color: #eb0000;
  }

  .carouselContainer {
    height: 614px;
    padding-bottom: 0;
    background-color: unset;
    /* background-image: none !important; */
  }

  .carouselContainer::before {
    top: unset;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(0deg, rgb(0, 0, 0) 60%, transparent);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
  }

  .carouselContainer::after {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: -5%;
    left: -5%;
    width: 110%;
    height: 40%;
    background: linear-gradient(transparent, #000);
  }

  .isLive.carouselContainer::after {
    height: 60%;
    background: linear-gradient(transparent, #000 65%);
  }

  .slideItem {
    margin: 0;
    background-color: #000;
    position: relative;
    align-items: unset;
    justify-content: unset;
    gap: 0;
  }

  .slideItem .imgContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
    z-index: 1;
  }

  .slideItem .imgContainer {
    display: block;
    width: 100%;
    box-shadow: none;
    height: 498px;
    aspect-ratio: unset;
    margin-bottom: unset;
  }

  .slideItem .imgContainer,
  .slideItem img {
    border-radius: 7px;
  }

  .slideItem .tag {
    top: 24px;
    left: 24px;
    font-size: 14px;
    letter-spacing: -0.28px;
    padding: 8px 12px;
  }

  .slideItem .slideLogo {
    bottom: 38%;
    display: flex;
  }
  .containerThing {
    display: unset;
    flex: unset;
    width: unset;
  }
  .slideItem .slideText {
    position: absolute;
    bottom: 30%;
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    max-width: unset;
  }

  .slideItem .slideCTA {
    position: absolute;
    bottom: 19%;
  }

  .slideItem .episodes {
    display: unset;
    background-color: #fff;
    color: #151515;
    font-size: 15px;
    font-weight: 600;
    line-height: 120%; /* 18px */
    min-width: 160px;
    text-align: center;
    padding: 8px 16px;
  }

  .indicatorsContainer {
    display: block;
    bottom: 25px;
  }
  .indicatorInner {
    background-color: #f5f5ff;
  }
  .indicatorOuter {
    width: 100%;
    margin: 0 5px;
    padding: 10px 0;
  }
  .indicator {
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.4);
  }
  .indicator.isActive {
    animation: unset;
  }
  .chevronContainer {
    display: flex;
  }
  .chevron {
    display: block;
  }
  .slideItem .watchNow {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    line-height: 120%; /* 18px */
    min-width: 160px;
    align-items: center;
    justify-content: center;
  }
  .slideItem .watchNow > svg {
    display: unset;
  }
}

@media (min-width: 1000px) {
  .breakingNewsContent .newsText {
    width: 86%;
  }
}

@media (min-width: 950px) {
  .breakingNewsContent .newsText {
    padding-left: 24px;
    display: flex;
    gap: 12px;
    align-items: center;
    flex-direction: row;
    justify-content: unset;
  }
}

/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!                                !!
 * !! TEMP UNTIL PROPER SIZED IMAGES !!
 * !!                                !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
@media (min-width: 1500px) {
  .carouselContainer::before {
    background: linear-gradient(0deg, rgb(0, 0, 0) 22%, rgba(0, 0, 0, 0.6), transparent);
  }

  .slideItem {
    align-items: unset;
  }
}
/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!                                !!
 * !!            END TEMP            !!
 * !!                                !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

@keyframes indicatorFill {
  from {
    width: 10%;
  }
  to {
    width: 100%;
  }
}

@keyframes indicatorFull {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0);
  }
}

@keyframes expand {
  from {
    width: 8px;
  }
  to {
    width: 23px;
  }
}

/* CSS for top stories */
.topStoriesMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 1/3;
  padding: 0 5%;
}
.topStoriesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 22px;
  gap: 12px;
}
.topStoryContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
}
.topStoriesNumber {
  width: 21px;
  color: #666;
  font-family: 'Libre Franklin';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  align-self: flex-start;
}
.topStoryTextContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}
.topStoryTitle {
  color: #0d0d0d;
  font-size: 16px;
  font-weight: 600;
  line-height: 135%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.topStoryAuthor {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  color: #666;
  letter-spacing: 0.24px;
}
.topStoriesDivider {
  border-bottom: 1px solid #e7e7e7;
  align-self: flex-end;
  width: 100%;
}
.topStoryContainer:first-of-type + .topStoriesDivider {
  border-bottom: 1px solid transparent;
}
.topStoriesDivider:last-child {
  display: none;
}
.topStoriesImage {
  display: none;
}
.topStoryContainer:first-of-type > .topStoryTextContainer > img {
  display: block;
  width: 100%;
  border-radius: 4px;
}
.topStoryContainer > .topStoryTextContainer > img {
  display: none;
}
@media screen and (min-width: 550px) {
  .topStoriesContainer {
    gap: 26px;
  }
  .topStoryContainer {
    gap: 16px;
  }
  .topStoryContainer:first-of-type + .topStoriesDivider {
    border-bottom: 1px solid #e7e7e7;
  }
  .topStoriesNumber {
    width: 26px;
    color: rgba(102, 102, 102, 0.4);
    display: flex;
    align-items: center;
    font-size: 40px;
    font-weight: 100;
    line-height: 100%;
    letter-spacing: -2px;
    margin-right: 14px;
    align-self: center;
  }
  .topStoriesNumber::before {
    content: '';
  }
  .topStoryTextContainer {
    gap: 8px;
  }
  .topStoryTitle {
    line-height: 140%;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
  .topStoryAuthor {
    font-size: 14px;
    line-height: 125%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .topStoriesImage {
    display: unset;
    border-radius: 3px;
    width: 70px;
    height: 70px;
  }
  .topStoryContainer:first-of-type > .topStoryTextContainer > img {
    display: none;
  }
  .topStoriesDivider {
    width: 100%;
  }
}
@media screen and (min-width: 1000px) {
  .topStoriesMain {
    grid-column: 3/3;
    padding: 0;
  }
}

/* Election wire styles */

.electionWireContainer {
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  width: 100%;
  max-width: 1090px;
  border: 1px solid #ebebeb;
  border-radius: 32px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04), 0px 64px 68px -48px rgba(0, 0, 0, 0.1);
  margin: 72px auto 96px auto;
  padding: 47px 73px;
  box-sizing: border-box;
  align-items: flex-start;
  background-image: url('https://dailywireplus-v2.imgix.net/manual-uploads/map.png');
  background-position: 95%;
  background-size: 300px;
  background-repeat: no-repeat;
  transition-duration: all 0ms !important;
  border-left: #f33738 38px solid;
  position: relative;
}
.electionWireContainer > img.desktopBanner {
  color-scheme: dark;
  filter: grayscale(100%) brightness(20%);
  width: 338px;
  object-fit: cover;
  height: 53px;
  flex-shrink: 0;
  margin-left: -15px;
}

.electionWireContainer * {
  transition-duration: all 0ms !important;
}

.electionWireContainer > svg {
  position: relative;
  right: -2px;
  margin-bottom: 16px;
}

.electionWireContainer > h2 {
  color: #000;
  font-size: 60px;
  font-weight: 400;
  line-height: 90%; /* 54px */
  letter-spacing: -2.4px;
  position: relative;
  right: 4px;
}

.electionWireContainer > p {
  color: #525252;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: -0.18px;
  margin: 12px 0 20px 0;
  max-width: 60%;
}

.electionWireContainer > a {
  display: flex;
  height: 58px;
  justify-content: center;
  align-items: center;
  border-radius: 56px;
  text-decoration: none;
  color: var(--Text-Inverse-Base, #0a0909);
  font-size: 16px;
  font-weight: 600;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.32px;
}

.desktopBanner {
  display: none;
}

.mobileBanner {
  display: block;
}
.anchorText {
  z-index: 2;
}

@media screen and (max-width: 1100px) {
  .electionWireContainer {
    margin: 28px auto 32px auto;
  }
  .electionWireContainer > h2 {
    font-size: 52px;
  }
  .electionWireContainer > p {
    font-size: 16px;
  }
}
@media screen and (max-width: 999px) {
  .electionWireContainer {
    align-items: center;
    height: 620px;
    background-position: 50% 68%;
    background-size: 310px;
    max-width: calc(792px);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04), 0px 40px 52px -56px rgba(0, 0, 0, 0.2);
    padding: 48px 32px;
    margin: 20px auto 24px auto;
    border-left: 0;
    overflow: hidden;
  }

  .electionWireContainer > svg {
    right: unset;
    margin-bottom: 32px;
  }

  .electionWireContainer > img.mobileBanner {
    filter: none;
    width: 100%;
    object-fit: cover;
    height: 110px;
    flex-shrink: 0;
    margin-left: -5px;
    position: absolute;
    top: 0;
    overflow: hidden;
    margin-left: 1px;
  }

  .electionWireContainer > img.desktopBanner {
    display: hidden;
    color-scheme: dark;
    filter: grayscale(100%) brightness(20%);
    width: 338px;
    object-fit: cover;
    height: 53px;
    flex-shrink: 0;
    margin-left: -15px;
  }

  .electionWireContainer > h2 {
    right: unset;
    text-align: center;
    font-size: 48px;
    max-width: 290px;
  }
  .electionWireContainer > p {
    flex: 1;
    text-align: center;
    font-size: 16px;
    max-width: 360px;
    margin: 12px 0 0 0;
    position: relative;
    top: 100px;
  }
}
@media screen and (max-width: 768px) {
  .electionWireContainer {
    align-items: center;
    height: 556px;
    background-size: 250px;
    overflow: hidden;
  }
  .electionWireContainer > h2 {
    font-size: 42px;
    max-width: 270px;
  }
  .electionWireContainer > p {
    font-size: 16px;
    max-width: 310px;
    position: relative;
    top: 80px;
  }
}

@media screen and (max-width: 549px) {
  .electionWireContainer {
    margin: 0 auto 24px auto;
    background-color: #fdfdfd;
    overflow: hidden;
  }
  .electionWireContainer > a {
    padding-top: 12rem;
  }
}

@media (min-width: 1000px) {
  .desktopBanner {
    display: block;
  }

  .mobileBanner {
    display: none;
  }
}

/* Election Page Takeover Banner Styles */
.electionBanner {
  width: 100%;
  margin: auto;
  height: 55px;
  min-width: 330px;
  background-color: #f72a2a;
  object-fit: contain;
  transition: all 0.7s ease-in-out;
  margin-top: 0.65rem;
  margin-right: 5%;
  margin-left: 5%;
}

@media (min-width: 640px) {
  .electionBanner {
    height: 65px;
  }
}

@media (min-width: 768px) {
  .electionBanner {
    height: 75px;
  }
}

@media (min-width: 1024px) {
  .electionBanner {
    height: 80px;
  }
}

@media (min-width: 1280px) {
  .electionBanner {
    height: 90px;
  }
}

.ElectionNightHeader {
  color: #ee252f;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Quincy CF';
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 115%;
  letter-spacing: -1px;
  text-transform: uppercase;
  padding-top: 40px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.ElectionNightHeader span {
  color: #000;
  margin-left: 5px;
}

.LiveBlogContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
}

.ElectionArticlesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  padding-top: 3rem;
  background-color: #f7f7f7;
}

.airElectionBanner {
  border-radius: 32px;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 65%;
}

@media (max-width: 1080px) {
  .airElectionBanner {
    width: 72%;
  }
}

@media (min-width: 975px) {
  .ElectionArticlesContainer {
    margin-top: 0;
  }
}

/* gift-guide */

.giftGuide {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  max-width: 1200px;
  width: 100%;
  margin: 40px auto;
}
.giftItem {
  width: 100%;
  aspect-ratio: 16 / 9;
  height: 100%;
}
.giftImage {
  aspect-ratio: 16 / 9;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  display: block;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
}
.giftItem:nth-of-type(1) {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
}
.giftItem:nth-of-type(2) {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
}
.giftItem:nth-of-type(3) {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
}
.giftIteme:nth-of-type(5) {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
}
.giftItem:nth-of-type(5) {
    grid-row: 4 / 5;
    grid-column: 2 / 3;
}

@media screen and (min-width: 1000px) {
  .giftGuide {
    margin: 88px auto;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
  .giftItem:nth-of-type(1) {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
}
.giftItem:nth-of-type(2) {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
}
.giftItem:nth-of-type(3) {
    grid-row: 1 / 2;
    grid-column: 4 / 5;
}
.giftIteme:nth-of-type(5) {
    grid-row: 2 / 3;
    grid-column: 3 / 4;
}
.giftItem:nth-of-type(5) {
    grid-row: 2 / 3;
    grid-column: 4 / 5;
}
}