.section {
  padding: 96px 0 0 0;
}
.posts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 16px;
  column-gap: 16px;
}
.post {
  display: flex;
  flex-direction: column;
  background-color: var(--Decorative-White, #FFF);
  height: 308px;
  border-radius: 8px;

}
.post > img {
  width: 100%;
  aspect-ratio: 16 / 6;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  height: 100%;
}
.textContainer > h3 {
  color: var(--GS-Brand-GS-Black, #241D1D);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Libre Franklin";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.textContainer > p {
  color: var(--neutrals-60, #666);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: auto;
}
.textContainer > span {
  color: #666;
  font-family: "Libre Franklin";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  display: none;
}
@media  screen and (min-width: 1200px) {
  .posts > a > .post {
    grid-column: auto;
  }
  .wideItem > .post > .textContainer > span {
    display: unset;
  }
  .wideItem:first-of-type > .post > .textContainer > h3 {
    font-size: 24px;
    line-height: 130%;
    
  }
  .wideItem > .post > .textContainer > h3 {
    font-size: 24px;
    line-height: 125%;
    -webkit-line-clamp: 5;
    
  }
  .wideItem > .post > .textContainer {
    padding: 24px;
  }
  .wideItem {
    grid-column: span 3;
  }
  .wideItem > .post {
    flex-direction: row;
    border-radius: 8px;

  }
  .wideItem > .post > img {
    width: calc((100%/3)*2);
    max-width: 692px;
    order: 1;
    aspect-ratio: unset;
    object-fit: cover;
    object-position: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
  }
.textContainer > h3 {
  -webkit-line-clamp: 4;
}

}
@media screen and (max-width: 1199px) {
  .posts {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 999px) {
  .section {
    padding: 23px 0 0 0;
  }
  .posts {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 22px;
  }
  .post {
    height: 308px;
    border-radius: 8px;
  }
  .textContainer > h3 {
    font-size: 20px;
    line-height: 135%;
  }
}
@media screen and (max-width: 549px) {
  .posts {
    grid-template-columns: repeat(1, 1fr);
  }
  .post {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
    border-bottom: 1px solid #B8B8B8;
    padding-bottom: 22px;
    height: unset;
    background-color: unset;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .post > img {
    width: 84px;
    height: 92px;
    object-fit: cover;
    object-position: center;
    border-radius: 2px;
  }
  .textContainer {
    gap: 4px;
    margin-top: 0;
    order: -1;
    padding: unset;
  }
  .textContainer > h3 {
    font-size: 14px;
    line-height: 150%; /* 21px */
  }
  .textContainer > p {
    font-size: 12px;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
  }
  .posts > a:nth-of-type(4n + 1) > .post {
    flex-direction: column;
    gap: 22px;
    align-items: unset;
  }
  .posts > a:nth-of-type(4n + 1) > .post > img {
    width: 100%;
    aspect-ratio: 16 / 6;
    height: unset;
  }
  .posts > a:nth-of-type(4n + 1) > .post > .textContainer {
    order: unset;
  }
}
