.sectionHeader {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}
.sectionHeader > h2 {
  color: #0D0D0D;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Libre Franklin";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 18.4px */
  letter-spacing: 2px;
  text-transform: uppercase;
  flex: 1;
  text-align: center;
}
.sectionHeaderCTA {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #141414;
  font-family: "Libre Franklin";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 17.6px */
  letter-spacing: 0.64px;
  text-transform: uppercase;
  text-decoration: none;
}
@media screen and (max-width: 999px) {
  .sectionHeader {
    margin-bottom: 22px;
  }
  .sectionHeader > h2 {
    font-size: 14px;
    line-height: 115%; /* 15.4px */
    letter-spacing: 2px;
    text-align: start;
  }
  .sectionHeaderCTA {
    font-size: 14px;
    line-height: 115%; /* 15.4px */
    letter-spacing: 0.48px;
  }
}