.ElectionNightContainer {
  min-width: 350px;
  padding: 54px 14px 10px;
  width: 100%;
  background-color: #f7f7f7;
  max-width: 1550px;
}

.ElectionNightInnerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: 2px;
  white-space: normal;
}

.ElectionNightHeader {
  color: #ee252f;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Quincy CF';
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 115%;
  letter-spacing: -1px;
  text-transform: uppercase;
  margin: auto;
  padding: 0 0 1rem 0;
}
.ElectionNightHeader span {
  color: #000;
}

.firstArticleContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.ElectionNightGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* gap: 1rem; */
  height: 100%;
}

@media (min-width: 1024px) {
  .ElectionNightGrid {
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
    gap: 1rem;
  }
}

@media (min-width: 640px) {
  .ElectionNightInnerContainer {
    font-size: 1rem;
    white-space: nowrap;
  }
}

@media (min-width: 640px) {
  .ElectionNightContainer {
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2rem;
  }
}

@media (min-width: 1024px) {
  .ElectionNightContainer {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2rem;
  }
}

@media (min-width: 1280px) {
  .ElectionNightContainer {
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2rem;
  }
}

.ElectionNightArticleLink {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: inherit;
  box-shadow: none;
  border-radius: 8px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .ElectionNightArticleLink {
    flex-direction: row-reverse;
    height: 100%;
    background-color: white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  }
}

.ElectionNightArticleImage {
  width: 100%;
  object-fit: cover;
  max-height: 28rem;
  border-radius: 3px;
}

@media (min-width: 1024px) {
  .ElectionNightArticleImage {
    width: 65.8%;
    min-width: 66.23%;
    max-height: 25rem;
    border-radius: 0;
  }
}

.ElectionNightArticleContent {
  display: flex;
  flex-direction: column;
  line-height: 2;
  padding-top: 1rem;
  padding-bottom: 0.25rem;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 1024px) {
  .ElectionNightArticleContent {
    padding-top: 10px;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 2rem;
    width: calc(100% - 65.8%);
  }
}

.ElectionNightArticleText {
  font-size: 32px;
  font-weight: 600;
  padding-right: 0.5rem;
  padding-top: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: 125%;
}

@media (max-width: 767px) {
  .ElectionNightArticleText {
    font-size: 16px;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .ElectionNightArticleText {
    font-size: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .ElectionNightArticleText {
    font-size: 32px;
    padding-top: 2.25rem;
  }
}

.ElectionNightFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
}

.ElectionNightTextStyle {
  color: #666;
  line-height: 22px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  width: 80%;
}
@media (max-width: 767px) {
  .ElectionNightTextStyle {
    font-size: 12px;
  }
}

/* Medium screens and above */
@media (min-width: 1024px) {
  .ElectionNightTextStyle {
    margin-top: 2rem;
  }
}
@media (min-width: 1280px) {
  .ElectionNightTextStyle {
    font-size: 14px;
    margin-bottom: 0.75rem;
  }
}

@media (min-width: 1280px) {
  .ElectionNightTextStyle {
    font-size: 14px;
  }
}

/* .ElectionNightDivider {
  border-top: 1px solid #b8b8b8;
  display: block;
  margin: 2rem 0;
  width: 100px;

}

@media (min-width: 1024px) {
  .ElectionNightDivider {
    display: none;
  }
} */

.ElectionNightGridContainer {
  width: 100%;
  display: none;
  background-color: inherit;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .ElectionNightGridContainer {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    background-color: white;
  }
}

.ElectionNightImage {
  width: 100%;
  object-fit: cover;
  height: 10rem;
}

.ElectionNightFlexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100%;
}

.ElectionNightTextParagraph {
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  font-weight: 600;
  line-height: 135%;
  /* margin: 0.75rem 0; */
  padding-left: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@media (min-width: 1024px) {
  .ElectionNightTextParagraph {
    font-size: 20px;
    margin: 0.75rem 0;
  }
}

@media (min-width: 1280px) {
  .ElectionNightTextParagraph {
    font-size: 20px;
  }
}
.ElectionNightFlexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ElectionNightScrollContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 330px;
}

.ElectionNightScrollContainer::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1024px) {
  .ElectionNightScrollContainer {
    display: none;
  }
}

.ElectionNightFlexItem {
  min-width: 330px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ElectionNightFlexColumnFull {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: inherit;
  height: 100%;
}

@media (min-width: 1024px) {
  .ElectionNightFlexColumnFull {
    background-color: white;
  }
}

.ElectionNightImageStyle {
  width: 6.5rem;
  object-fit: cover;
  height: 6.5rem;
  border-radius: 3px;
}

.ElectionNightFlexGrowColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ElectionNightArticlesThreeContainer {
  display: none;
}

@media (min-width: 1024px) {
  .ElectionNightArticlesThreeContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    grid-column: span 4;
  }
}
.ElectionNightFlexColumnFullWhite {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.ElectionNightImageStyleTopRounded {
  width: 100%;
  object-fit: cover;
  height: 10rem;
}
.ElectionNightFlexGrowColumnPadding {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}
.ElectionNightTextEllipsis {
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 600;
  line-height: 135%;
  margin: 1rem 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  flex-grow: 1;
  overflow: hidden;
}

@media (min-width: 1280px) {
  .ElectionNightTextEllipsis {
    font-size: 20px;
  }
}
.ElectionNightTextStyleAutoMargin {
  color: #666;
  line-height: 22px;
  font-size: 14px;
  margin-top: 20%;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
}

@media (min-width: 1280px) {
  .ElectionNightTextStyleAutoMargin {
    font-size: 14px;
  }
}
.articleSpan3 {
  grid-column: span 3;
}

.articleSpan1 {
  grid-column: span 1;
}

@media (min-width: 1024px) {
  .ElectionNightGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.viewAllLink {
  text-align: center;
  font-family: 'Libre Franklin';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-decoration-line: underline;
  cursor: pointer;
}

.viewAllContainer {
  width: max-content;
  margin: auto;
  padding-top: 15px;
}

@media (min-width: 1024px) {
  .viewAllLink {
    font-size: 18px;
    letter-spacing: 0.4px;
    padding-top: 10px;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }

  .viewAllContainer {
    padding-top: 22px;
  }
}

.SkeletonImg2nd {
  width: 100%;
  object-fit: cover;
  min-height: 140px;
  max-height: min-content;
}

.Skeleton {
  background-color: #ddd;
  overflow: hidden;
  border-radius: 4px;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.SkeletonTextTitle {
  height: 20px;
  width: 100%;
  line-height: 135%;
  margin: 10.5px 0;
}

.SkeletonTextAuthorMain {
  line-height: 22px;
  margin-top: 7px; 
  margin-bottom: 7px;
  margin-right: 3.5px; 
  width: 80%;
  height: 20px;
}

.SkeletonTextAuthor2nd {
  line-height: 22px;
  margin-bottom: 21px;
  margin-right: 3.5px;
  width: 80%;
  height: 20px;
}

.SkeletonTextAuthor2nd {
  line-height: 22px;
  margin-bottom: 21px;
  margin-right: 3.5px;
  width: 80%;
  height: 20px;
}

.SkeletonTextAuthor2nd {
  line-height: 22px;
  margin-bottom: 21px;
  margin-right: 3.5px;
  width: 80%;
  height: 20px;
}

.ArticleSkeletonText:nth-child(1) {
  width: 100%;
  height: 25px;
  margin-right: 28px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.ArticleSkeletonText {
  width: 100%;
  height: 25px;
  margin-right: 28px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.SkeletonImgMain {
  width: 100%;
  height: 252px;
  max-height: 100%;
}

@media (min-width: 1024px) {
  .SkeletonImgMain {
    width: 68%;
    height: 350px;
    max-height: 100%;
  }
}
